"use strict";

var headerH = 64;
var windowH = 768;

$(document).ready(function(){

    headerH = $('.header').height();
    windowH = $(window).height();


    $('.grid').masonry({
        columnWidth: '.news-one-sizer',
        itemSelector: '.news-one'
    });

    $('.menu-toggle').on('click', function (e) {
        var ths = $(this);
        var menu = $('.header-inside');
        if (!ths.hasClass('active')) {
            openMenu();
        } else {
            closeMenu();
        }

       e.preventDefault();
    });

    $('.stay-one__toggle').on('click', function(e){
        var ths = $(this);
        var head = ths.closest('.stay-one__head');
        var stayBody = head.next('.stay-one__body');
        if (!head.hasClass('active')) {
            head.addClass('active');
            stayBody.slideDown(300);
        } else {
            head.removeClass('active');
            stayBody.slideUp(300);
        }
        e.preventDefault();
    });

    $('.slider-about').slick({
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 3,
        responsive: [
            {
                breakpoint: 1023,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
        ]
    });


    $('.form-input').on('focus', function(){
        var ths = $(this);
        var label = ths.closest('label');
        label.addClass('focused');
    });
    $('.form-input').on('blur', function(){
        var ths = $(this);
        var label = ths.closest('label');
        label.removeClass('focused');
    });
    $('.form-input').on('input', function(){
        var ths = $(this);
        var label = ths.closest('label');
        if (ths.val() != '') {
            label.addClass('activated');
        } else {
            label.removeClass('activated');
        }
    });

    $(".main-menu a").click(function(e) {
        let ths = $(this);
        let thsAttr = ths.attr('href').split('#')[1];
        let currSection = $('section[data-id='+ thsAttr +']');
        $([document.documentElement, document.body]).stop(false, false).animate({
            scrollTop: currSection.offset().top - headerH
        }, 1000);
        closeMenu();
        e.preventDefault();
    });

    $(window).on('scroll',function () {
        let scrTop = $(window).scrollTop();
        let sectionArr = $('section[data-id]');


        sectionArr.each((index, element)  => {
            let elem = $(element);
            let elemOffTop = elem.offset().top;
            // let elemHeight = elem.outerHeight();
            let elemId = elem.attr('data-id');

            if (elemOffTop - headerH <= scrTop + 5) {
                setActiveMenu(elemId);
            }
        });

    });

});

function setActiveMenu(currId) {
    let menu = $('.main-menu');
    menu.find('.active').removeClass('active');
    menu.find('a[data-id='+ currId +']').addClass('active');
};

function openMenu() {
    var ths = $('.menu-toggle');
    var menu = $('.header-inside');
    ths.addClass('active');
    menu.slideDown(300)
};
function closeMenu() {
    var ths = $('.menu-toggle');
    var menu = $('.header-inside');
    ths.removeClass('active');
    menu.slideUp(300)
};

$(window).on('resize', function () {
    headerH = $('.header').height();
    windowH = $(window).height();
});
